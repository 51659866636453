import React, { useState } from "react";

export default function Agence() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <section id="Agence" className="about--section">
      <div className="about--section--img" style={{justifySelf: 'center'}}>
        <iframe title="Google Maps Lille" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2530.9869695923076!2d3.105579611410063!3d50.62735887449285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2d60f85e00a5d%3A0xad8305721b6dbc3b!2sDirectt%20Permis!5e0!3m2!1sfr!2sfr!4v1699377509809!5m2!1sfr!2sfr" minWidth="200px" maxWidth="600px" width="100%" height="500px" loading="lazy" style={{border: '2px solid var(--primary)', borderRadius: '5px'}}></iframe>
        <p className="agence--section-description">
        <b>🤝 Nos partenaires</b>
      </p>
      <div className="button-align-section" style={{marginBottom: '2rem'}}>
        <a style={{textDecoration: 'none', color: 'currentColor', marginRight: '1rem'}} href="" target="_blank">
          <button className="btn btn-outline-github">
            <b>🔗 Partenaire 1</b>
          </button>
        </a>
        <a style={{textDecoration: 'none', color: 'currentColor', marginRight: '1rem'}} href="" target="_blank">
          <button className="btn btn-outline-github">
            <b>🔗 Partenaire 2</b>
          </button>
        </a>
        <a style={{textDecoration: 'none', color: 'currentColor'}} href="" target="_blank">
          <button className="btn btn-outline-github">
            <b>🔗 Partenaire 3</b>
          </button>
        </a>
      </div>
      </div>
      <div className="agence--section--content--box about--section--box">
        <div className="agence--section--content">
          <p className="section--title">🏠 L' agence</p>
          <h2 className="section--heading">À <span style={{color: 'var(--primary)'}} >propos</span>{" "}de nous !</h2>
          <p className="agence--section-description" style={{textAlign: 'justify'}}>
            Directt Permis est une école de conduite professionnelle et conviviale. Notre établissement est à la fois labelisé, ainsi que datadok centre de formation. Cela nous permet de vous proposer énormément de formations financées par différents organismes (CPF, etc...).
          </p>
          <button role="button" onClick={() => setShowModal(true)} className="btn btn-github">
            <b>💰 Les méthodes de financement</b>
          </button>
          {showModal ? (
          <>
            <div
              style={{justifyContent: 'center', alignItems: 'center', display: 'flex', top: '0', right: '0', bottom: '0', left: '0', padding: '1rem', overflowX: 'hidden', overflowY: 'auto', position: 'fixed', inset: '0', zIndex: '50', outlineStyle: 'none', cursor: 'default'}}
            >
              <div style={{position: 'relative', width: 'auto', marginTop: '1.5rem', marginBottom: '1.5rem', marginLeft: 'auto', marginRight: 'auto', maxWidth: '48rem'}}>
                {/*content*/}
                <div style={{borderWidth: '0', borderRadius: '0.5rem', boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)', position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#ffffff', outlineStyle: 'none'}}>
                  {/*header*/}
                  <div style={{display: 'flex', padding: '1.25rem', justifyContent: 'space-between', alignItems: 'flex-start', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', borderTopWidth: '0px', borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '1px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                    <h3 style={{fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: '600', color: 'var(--primary)' }}>
                      💰 Les méthodes de financement
                    </h3>
                    <button
                      style={{float: 'right', padding: '0.25rem', borderWidth: '0', outlineStyle: 'none', fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: '600', lineHeight: '1', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}
                      onClick={() => setShowModal(false)}
                    >
                      <span style={{display: 'block', outlineStyle: 'none', width: '1.5rem', height: '1.5rem', fontSize: '1.5rem', lineHeight: '2rem', color: '#000000', backgroundColor: 'transparent', opacity: '0.05'}}>
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div style={{position: 'relative', padding: '1.5rem', flex: '1 1 auto', textAlign: 'center'}}>
                    <h3 style={{marginTop: '0.25rem', marginBottom: '0.25rem', fontWeight: '700'}}>Grâce à Directt Permis vous pouvez bénéficier de différents modes de financement !</h3>
                    <p style={{marginTop: '1.25rem', fontSize: '0.875rem', lineHeight: '1.25rem', lineHeight: '1.625'}}>
                    Directt permis a souscrit à une garantie financière.<br/>
                    Possibilité selon étude de votre dossier de mettre en place votre CPF pour financer votre permis.<br/>
                    Si vous êtes en contrat d'apprentissage vous avez peut-être des aides ....Renseignez vous?<br />
                    Possibilité d' aide avec le FASRR (restauration)<br />
                    </p>
                  </div>
                  {/*footer*/}
                  <div style={{display: 'flex', padding: '0.5rem', justifyContent: 'flex-end', alignItems: 'center', borderBottomRightRadius: '0.5rem', borderBottomLeftRadius: '0.5rem', borderTopWidth: '1px',borderLeftWidth: '0px', borderRightWidth: '0px', borderBottomWidth: '0px', borderStyle: 'solid', borderColor: '#ff000042'}}>
                    <button
                      style={{color: 'var(--primary)', paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', marginRight: '0.25rem', marginBottom: '0.25rem', outlineStyle: 'none', fontSize: '0.875rem', lineHeight: '1.25rem', fontWeight: '700', textTransform: 'uppercase', transitionProperty: 'all', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms', transitionTimingFunction: 'linear', border: 'none', background: 'transparent', cursor: 'pointer'}}
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      J'ai compris ✅
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{position: 'fixed', top: '0', right: '0', bottom: '0', left: '0', zIndex: '40', backgroundColor: '#000000', opacity: '0.25'}}></div>
          </>
          ) : null}
          <p className="agence--section-description">
            <b>
            📌 160 Rue Roger Salengro, 59260 Lille <br />
            📧 contact@directtpermis.com <br />
            📞 03 62 13 48 72 <br />
            <nav className="HoursNav">
              <label for="touch"><span className="HoursTitle">⏰ Horaires</span></label>               
              <input type="checkbox" id="touch" /> 
              <ul className="HoursSlide">
                <p>Lun. ❌ Fermé</p>
                <p>Mar. ✅ 16h-18h</p>
                <p>Mer. ✅ 16h-18h</p>
                <p>Jeu. ✅ 16h-18h</p>
                <p>Ven. ✅ 16h-18h</p>
                <p>Sam. ❌ Fermé</p>
                <p>Dim. ❌ Fermé</p>
              </ul>
            </nav> 
            </b>
          </p>
        </div>
      </div>
    </section>
  );
}

import { Link } from "react-scroll";
import Typical from 'react-typical';

export default function landingPage() {
  return (
    <section id="landingPage" className="agence--section">
      <div className="agence--section--content--box noselect">
        <div className="agence--section--content">
          <p className="section--title">Bienvenue sur votre Auto-Moto école en ligne !</p>
          <h1 className="agence--section--title">
            <span className="agence--section-title--color">Directt Permis,<br />vous souhaitez passer</span>{" "}
            <Typical
                steps={['le permis', 1000, 'le code', 1000, 'la passerelle', 1000]}
                loop={Infinity}
                wrapper="p"
              />
          </h1>
          <p className="agence--section-description">
            <img className="landing-img-formations" style={{maxWidth: '80%'}} src="./img/icon2.png" alt="Formations Icon" />
            <br /><br />  
          </p>
        </div>
          <Link
            activeClass="navbar--active-content"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            to="Formations"
            className="navbar--content"
            style={{marginRight: '1rem'}}
          >
            <button className="btn btn-primary">
              <b>C'est parti !</b>
            </button>
          </Link>
          <Link
            activeClass="navbar--active-content"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            to="Contact"
            className="btn btn-outline-primary"
            style={{marginTop: '0.5rem'}}
          >
            <b>📝 Contactez-nous</b>
          </Link>
      </div>
      <div className="agence--section--img">
        <img src="./img/talisman4.png" alt="Car Image" />
      </div>
    </section>
  );
}
